/* eslint-disable max-len */
/**
 * https://javascript.plainenglish.io/how-to-query-remote-graphql-data-with-apollo-in-gatsby-d199a3e618d0
 */
import { gql } from '@apollo/client'

const commonFields = `
nid: entityId
nodeType: entityBundle
url: entityUrl {
  path
}
language: entityLanguage {
  id
}
title
`

// Search query
export const SEARCH_QUERY = gql`
  query searchResults($langcode: String!, $language: LanguageId!, $keywords: String!, $page: Int!, $pageSize: Int!, $types: [String!], $storeId: StoreIdMultiViewFilterInput!) {
    search: solrSearchContentGraphql1View(
      page: $page
      pageSize: $pageSize
      filter: { 
        keys: $keywords, 
        type: $types, 
        langcode: $langcode, 
        store_id: $storeId
      },
    ) {
      results {
        entityTranslation(language: $language) {
          ... on NodeContactPage {
            ${commonFields}
          }
          ... on NodeCareersPage {
            ${commonFields}
          }
          ... on NodePage {
            ${commonFields}
            description: subtitle {
              processed
            }
          }
          ... on NodeHcpLandingPage {
            ${commonFields}
            description: subtitle {
              processed
            }
          }
          ... on NodeFaqs {
            ${commonFields}
            description: subtitle {
              processed
            }
          }
          ... on NodeMultiStepPage {
            ${commonFields}
            description: listingDescription {
              processed
            }
          }
          ... on NodeProduct {
            ${commonFields}
            description: listingDescription {
              processed
            }
            image: listingImage {
              entity {
                entityTranslation(language: $language) {
                  ... on MediaMediaImage {
                    fieldMediaImage {
                      alt
                      derivative(style: SEARCHTHUMBNAIL) {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on NodeEvent {
            ${commonFields}
            description: summary {
              processed
            }
            image: eventImage {
              entity {
                entityTranslation(language: $language) {
                  ... on MediaMediaImage {
                    fieldMediaImage {
                      alt
                      derivative(style: SEARCHTHUMBNAIL) {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on NodeArticle {
            ${commonFields}
            description: summary {
              processed
            }
            image: articleImage {
              entity {
                entityTranslation(language: $language) {
                  ... on MediaMediaImage {
                    fieldMediaImage {
                      alt
                      derivative(style: SEARCHTHUMBNAIL) {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
