/**
 * https://javascript.plainenglish.io/how-to-query-remote-graphql-data-with-apollo-in-gatsby-d199a3e618d0
 */
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { SEARCH_QUERY } from './searchQuery.js'
import { useI18n } from '@ggs/gatsby/lib'
import { debounce, find, flatten, isEqual, uniqWith } from 'lodash'
import useSearchFilters from '@ggs/components/layout/Search/useSearchFilters'
import { getSearchParams } from '@ggs/utils'
import { useLayoutMeta } from '@ggs/hooks'

const PAGE_SIZE = 10

/**
 * @typedef {import('@apollo/client').ApolloError} ApolloError
 */
/**
 * @typedef {Object} SearchResult
 * @property {string} type
 * @property {string} title
 * @property {string} description
 * @property {string} url
 * @property {{alt:string,url: string}} image
 * @property {string} nodeType
 */

/**
 * @typedef {Object} SearchResultProps
 * @property {String=} url
 * @property {String=} title
 * @property {String=} description
 * @property {{id: string}} language
 * @property {{src:String,alt: String}} image
 */

/**
 * @typedef {Object} SearchProps
 * @property {Object} pageContext
 * @property {Object} serverData
 * @property {JSX.Element|Element} children
 */

/**
 * @typedef {Object} SearchState
 * @property {Array<SearchResultProps>} content
 * @property {string} keywords
 * @property {boolean} hasNextPage
 * @property {number} pageIndex
 * @property {Object} searchData

 */

/**
 *
 * @type {SearchState}
 */
const initialSearchState = {
  content: [],
  keywords: '',
  hasNextPage: false,
  pageIndex: 0,
  searchData: {},
}

/**
 * @typedef {Object} SearchQueryReturn
 * @property {SearchState} searchState
 * @property {(old:SearchState)=>void} setSearchState
 * @property {ApolloError} error
 * @property {boolean} loading
 * @property {function} refetch
 * @property {JSX.Element} renderedFilters
 */

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @param {SearchProps} props
 * @return {SearchQueryReturn}
 */
export default function useSearchQuery(props) {
  const { t } = useI18n()
  const { currentStore } = useLayoutMeta()
  const {
    pageContext: {
      // @ts-ignore
      data: {
        layoutMeta: { lang },
      },
    },
  } = props

  const [searchState, setSearchState] = useState(() => {
    // @ts-ignore
    const qs = getSearchParams(props?.location?.search)

    /** @type {SearchState} */
    return Object.assign(initialSearchState, {
      keywords: qs?.get('search') || '',
    })
  })
  const [searchFilters] = useState([
    {
      id: 'product',
      label: t('global:search.filters.products'),
    },
    {
      id: 'event',
      label: t('global:search.filters.events'),
    },
    {
      id: 'article',
      label: t('global:search.filters.news'),
    },
    {
      id: 'other',
      label: t('global:search.filters.otherPages'),
    },
  ])
  const { renderedFilters, activeFilters } = useSearchFilters({ categories: searchFilters })

  /**
   *
   * @param {Array<SearchResult>=} newResults
   * @param {Object=} newState
   */
  const adjustContentResults = (newResults = [], newState = {}) => {
    /**
     * Only pull OLD data, not latest if newState is available
     * @type {Array<SearchResult>}
     * */
    const newContent = uniqWith(
      [...flatten(Object.values(searchState?.searchData)), ...newResults].filter(
        (/** @type SearchResult */ { nodeType }) =>
          // Include if there are no filters
          !activeFilters.length
          // Of if there are filters and the current type is selecte
          || activeFilters.includes(nodeType)
          // Of if the other type is selected, and we're looking for other pages
          || (activeFilters.includes('other') && !['product', 'event', 'article'].includes(nodeType))
      ),
      isEqual
    )

    // For FAQs page, append keywords to speed up searching inside if available.
    const faqs = find(newContent, ['nodeType', 'faqs']) || null
    // @ts-ignore
    if (faqs?.url) {
      // Ensure to replace the whole query to avoid mangling the URL.
      // @ts-ignore
      faqs.url = faqs.url.split('?')[0] + `?search=${encodeURIComponent(searchState.keywords)}`
    }

    if (newContent.length) {
      // Finally, update results. If newState was included, leverage that too.
      // @ts-ignore
      setSearchState((prevState) => ({
        ...prevState,
        ...newState,
        content: newContent,
      }))
    }
  }

  /**
   *
   * @param {Array<SearchResult>=} data
   */
  const onCompleted = (data) => {
    const newState = {
      ...searchState,
    }
    // @ts-ignore
    const newResults = (data?.search?.results || []).map(({ entityTranslation: item }) => {
      const fieldMediaImage = item?.image?.entity?.entityTranslation?.fieldMediaImage

      return {
        type: 'SearchResult',
        title: item?.title || '',
        description: item?.description?.processed || '',
        url: item?.url?.path || '',
        image: {
          alt: fieldMediaImage?.alt || '',
          src: fieldMediaImage?.derivative?.url,
        },
        nodeType: item?.nodeType,
      }
    })
    // .filter((item) => item.title)

    // Update whether the results were under the page size, if not, we can load more.
    newState.hasNextPage = newResults.length >= PAGE_SIZE
    // Update results on page, only if we have new results.
    if (newResults.length) {
      // @ts-ignore
      newState.searchData[newState.pageIndex] = newResults

      adjustContentResults(newResults, newState)
    } else {
      // In case any other updates to stare are needed, apply now.
      setSearchState(newState)

      console.log('onCompleted.stateChange', newState)
    }
  }

  const searchQueryVaribles = {
    keywords: searchState.keywords,
    page: searchState.pageIndex,
    pageSize: PAGE_SIZE,
    // Array of ctype bundles.
    // @ts-ignore
    types: [],
    storeId: {
      value: currentStore?.entityId,
    },
    // Ok as is.
    langcode: lang,
    // Format language for Drupal
    language: lang?.toUpperCase()?.replace('-', '_'),
    // Todo: tie in keywords for search from query param.
  }

  const { loading, error, refetch } = useQuery(SEARCH_QUERY, {
    variables: searchQueryVaribles,
    notifyOnNetworkStatusChange: true,
    onCompleted,
    onError: (error) => {
      console.error(error)
    },
  })

  const cleanRefetch = debounce(() => {
    refetch()
  }, 500)

  // As filters change, do not refetch data, just tidy up the results to avoid needless server calls.
  useEffect(() => {
    adjustContentResults()
  }, [activeFilters])

  return {
    searchState,
    setSearchState,
    loading,
    error,
    refetch: cleanRefetch,
    renderedFilters,
  }
}
