import React from 'react'
import { SearchResult } from '@ggs/components/cards'
import { uniqueId } from 'lodash'

/**
 * @typedef {import('./useSearchActions').SearchDataReturn} SearchDataReturn
 * @typedef {import('./useSearchQuery').SearchQueryReturn} SearchQueryReturn
 */

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @param {SearchDataReturn & SearchQueryReturn} props
 * @return {JSX.Element}
 */
export default function SearchContents({
  searchState,
  loading,
  error,
  loadingData,
  loadMoreButton,
  // @ts-ignore
  children,
}) {
  const { content, keywords, hasNextPage } = searchState
  return (
    <>
      {error && <div>Error... {error.message}</div>}
      {loading
        && content.length === 0
        && loadingData.map((item, index) => (
          <SearchResult key={uniqueId('search_results_')} {...item} />
        ))}
      {!error && (
        <>
          {keywords && content.length > 0 ? (
            <>
              {content.map((item, index) => (
                <SearchResult
                  key={uniqueId(`search_results_${item.url}`)}
                  {...item}
                  // Doing this during the memo causes ESlint issues.
                  url={item.url.replace(`${item?.language?.id}/`, '')}
                />
              ))}
              {
                // If loading, display a single loading item, else, display the laod more button.
                !loading ? (
                  hasNextPage && loadMoreButton
                ) : (
                  <SearchResult key={uniqueId('search_results_')} {...loadingData[0]} />
                )
              }
            </>
          ) : (
            !loading && (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: keywords
                      ? `No results found for <strong>"${keywords}"</strong>. Please try searching for something else.`
                      : 'You have not provided any keywords to search for. Please try searching for something else.',
                  }}
                />
                {children}
              </>
            )
          )}
        </>
      )}
    </>
  )
}
