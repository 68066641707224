// @ts-nocheck
import React from 'react'
import { GridContainer, Layout, PageHeader, SearchInput } from '@ggs/components/layout'
import { graphql } from 'gatsby'
import { useI18n } from '@ggs/gatsby/lib'
import useSearchActions from './useSearchActions'
import SearchContents from './SearchContents'
import Grid from '@mui/material/Grid'
import { ProductGrid } from '@ggs/components/paragraphs'
import useSearchQuery from './useSearchQuery' /**
 * @typedef {Object} SearchProps
 * @property {Object} pageContext
 * @property {Object} serverData
 */

/**
 * @typedef {Object} SearchProps
 * @property {Object} pageContext
 * @property {Object} serverData
 */
/**
 *
 * @param {SearchProps} props
 * @return {JSX.Element}
 */
const Search = (props) => {
  const { t } = useI18n()
  const searchData = useSearchQuery(props)
  const searchActions = useSearchActions(searchData)
  const { renderedFilters } = searchData
  const { updateSearch } = searchActions

  // Taken from homepage
  const { featuredProducts } = props.pageContext.data || {}

  // Set page title for SEO
  props.pageContext.data.title = t('global:label.search')

  const labels = {
    placeholder: t('global:placeholder.searchSite'),
    ariaLabel: t('global:label.searchSite'),
  }

  return (
    <Layout className="page" {...props} sx={{ p: 0 }}>
      <PageHeader title={t('global:label.search')}>
        <SearchInput onSubmit={updateSearch} labels={labels} {...props} />
      </PageHeader>
      <GridContainer
        sx={{
          pt: { xs: 2, md: 8 },
          pb: { xs: 7, md: 10 },
          '.product-grid .heading-flare': { display: 'none !important' },
        }}
      >
        <Grid className="search-contents" container spacing={{ xs: 3, md: 5 }}>
          <Grid item className="search-contents__filters" xs={12} md={2}>
            {renderedFilters}
          </Grid>
          <Grid className="search-contents__listing" item xs={12} md={10}>
            <SearchContents {...searchActions} {...searchData}>
              {featuredProducts && (
                <ProductGrid
                  {...featuredProducts}
                  theme={ProductGrid.variant.default}
                  actionLabel={t('global:button.view')}
                  type={'Search-ProductGrid'}
                  id={'Search-ProductGrid'}
                />
              )}
            </SearchContents>
          </Grid>
        </Grid>
      </GridContainer>
    </Layout>
  )
}

export const query = graphql`
  query searchPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Search
