/**
 * https://javascript.plainenglish.io/how-to-query-remote-graphql-data-with-apollo-in-gatsby-d199a3e618d0
 */
import React, { useCallback, useState } from 'react'
import { Button } from '@ggs/gatsby/components/nav'
import { useI18n } from '@ggs/gatsby/lib'
import { debounce, delay } from 'lodash'
import { getWindow } from '@ggs/utils'

/**
 * @typedef {import('@apollo/client').ApolloError} ApolloError
 * @typedef {import('./useSearchQuery').SearchProps} SearchProps
 * @typedef {import('./useSearchQuery').SearchState} SearchState
 * @typedef {import('./useSearchQuery').SearchQueryReturn} SearchQueryReturn
 */

/**
 * @typedef {{loadingData: any[], loadMoreButton: JSX.Element, updateSearch: function}} SearchDataReturn
 *
 */

// eslint-disable-next-line valid-jsdoc
/**
 * @param {SearchQueryReturn} props
 * @return {SearchDataReturn}
 */
export default function useSearchActions({
  searchState,
  setSearchState,
  refetch,
}) {
  const { t } = useI18n()

  const loadingData = useState(
    Array(4).fill({
      type: 'SearchResult',
      title: '',
      description: '',
      url: '',
      image: {
        alt: '',
        src: '',
      },
    })
  )

  /**
   * When new keywords are entered, we start from scratch.
   * @param {string} keys
   */
  const updateSearch = debounce((keys = '') => {
    // Don't take action if nothing has changed.
    if (keys === searchState.keywords) {
      console.log('updateSearch: no new keywords')
      return
    }

    console.log('updateSearch: new keywords', keys)

    // @ts-ignore
    setSearchState((prevState) => ({
      ...prevState,
      keywords: keys,
      pageIndex: 0,
      hasNextPage: false,
      content: [],
      searchData: { 0: [] },
    }))

    /** @type {Window} */
    // @ts-ignore
    getWindow().history.replaceState({}, '', `?search=${encodeURIComponent(keys)}`)

    // Only refetch if we have keywords.
    // if (keys) {
    //   delay(() => refetch(), 1000)
    // }
  }, 500)

  // Display load more, as long as there's more pages
  const loadMoreOnClick = useCallback(() => {
    console.log('loadMoreOnClick.proposedState', {
      pageIndex: searchState.pageIndex,
      next: searchState.pageIndex + 1,
    })

    // @ts-ignore
    setSearchState((prevState) => ({
      ...prevState,
      pageIndex: prevState.pageIndex + 1,
    }))

    delay(() => refetch(), 1000)
  }, [searchState.pageIndex])

  const loadMoreButton = <Button label={t('global:button.loadMore')} onClick={loadMoreOnClick} />

  return {
    updateSearch,
    loadingData,
    loadMoreButton,
  }
}
